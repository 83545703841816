* {
    margin: 0;
    padding: 0;
}

/*

------ VARIABELEN ------

*/

:root {
    --main-pink: #FF0A85;
    --main-yellow: #FFE532;
    --main-text: #545758;
    --main-bkg: #F7FBFE;
    --sec-text: white;
    --sec-purple: #7A4FFF;
    --sec-green: #2DE1C2;
    --sec-blue: #34F6F2;
    --sec-yellow: #FFEF32;
    --sec-orange: #F8863D;
    --sec-gray: #EBF2F4;
}

/*

------ Fonts ------

*/


/*
---- Body ----
*/

@font-face {
    font-family: cooper-hewitt-book;
    src: url(fonts/CooperHewitt-Book.ttf);
}

@font-face {
    font-family: cooper-hewitt-medium;
    src: url(fonts/CooperHewitt-Medium.ttf);
}

@font-face {
    font-family: cooper-hewitt-italic;
    src: url(fonts/CooperHewitt-BookItalic.ttf);
}


/*
---- TITLES ----
*/

@font-face {
    font-family: poppins-regular;
    src: url(fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: poppins-medium;
    src: url(fonts/Poppins-Medium.ttf);
}


header {
    font-family: poppins-regular sans-serif;
    color: white;
    margin: 0;
    padding: 1em;
    font-size: 35px;
    display: flex;
    flex-direction: row;
}

header h1 {
    flex-grow: 1;
    margin: auto 0;
    text-transform: uppercase;
}

header a, header button {
    color: currentColor;
    margin: auto 0;
}

header a:hover, header button:hover {
    color: currentColor;
    opacity: 0.7;
}

.btn-icon {
    background: none;
    border: none;
}

/*

------ ALGEMENE OPMAAK ------

*/


body {
    font-family: cooper-hewitt-book, sans-serif;
    color: var(--main-text);
    width: 100%;
    /* background-color: var(--main-bkg); */
}

h1 {
    font-size: 1em;
    text-align: center;
}

main {
    width: 100%;
    margin: 0;
}

main > * {
    padding: 1% 5%;
}

main.builder {
    padding: 0;
}

div {
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
a {
    font-family: poppins-regular, sans-serif;
    padding: 0;
    margin: 0;
}

h2 {
    margin-bottom: 2%;
}

h4 {
    margin-bottom: 3%;
}

p {
    margin: 0;
}

img {
    vertical-align: unset;
}


/*
---- CLEARFIX ----
*/

.clearfix:after {
    visibility: hidden;
    display: block;
    content: ' ';
    clear: both;
    height: 0;
}

/*
---- HIDE ----
 */

.hide {
    display: none !important;
}

/*
---- IMG CONTAINER ----
*/

.img-container {
    text-align: center;
}


/*

------ NAVIGATION ------

*/


/*
---- NAVBAR ADMIN ----
*/

.navbar {
    padding: 0;
}

.admin-nav ul {
    text-align: right;
    padding: 0;
    margin: 0;
    float: right;
    width: 100%;
}

.admin-nav a {
    text-decoration: none;
    color: var(--sec-text);
    font-family: poppins-regular, sans-serif;
}

.admin-nav li {
    margin-left: 4%;
    list-style: none;
    display: inline-block;
    border-bottom: transparent solid 2px;
}

.admin-nav li:hover {
    color: white;
    border-bottom: var(--sec-text) solid 2px;
}

.nav-selected.active li {
    border-bottom: var(--sec-text) solid 2px;
}


/*

------ HEADERS & FOOTERS ------

*/

.spinner-holder {
    text-align: center;
}

/*
---- ADMIN ----
*/

.admin-header,
.admin-footer {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7a4fff+0,2de1c2+100 */
    background: var(--sec-purple);
    /* Old browsers */
    background: -moz-linear-gradient(left, var(--sec-purple) 0%, var(--sec-green) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, var(--sec-purple) 0%, var(--sec-green) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, var(--sec-purple) 0%, var(--sec-green) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--sec-purple)', endColorstr='var(--sec-green)', GradientType=1);
    /* IE6-9 */
}

.admin-footer {
    color: var(--sec-text);
    text-align: center;
    padding: 1%;
    font-size: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
}


/*
---- STUDENT ----
*/

.student_header {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7a4fff+0,2de1c2+100 */
    background: var(--main-yellow);
    /* Old browsers */
    background: -moz-linear-gradient(left, var(--main-yellow) 0%, var(--main-pink) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, var(--main-yellow) 0%, var(--main-pink) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, var(--main-yellow) 0%, var(--main-pink) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='var(--main-yellow)', endColorstr='var(--main-pink)', GradientType=1);
    /* IE6-9 */
}

/*

------ FILTER FORM ------

*/

.custom-select-single {
    font-size: inherit;
    background-color: var(--sec-text);
    font-family: cooper-hewitt-book, sans-serif;
    color: var(--main-text);
    /*padding: 2% 4%;*/
    margin: 0 0;
    border: 1px solid var(--sec-purple);
    box-sizing: border-box;
    text-align: left;
    border-radius: 0;
    height: auto;
    vertical-align: inherit;
    cursor: pointer;
    width: 100%;
}

.filter-form {
    font-size: 1.5em;
    margin: .5em;
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    text-align: center;
}

.filter-form > * {
    /*display: inline-block;*/
    flex-basis: 20%;
}

/*
---- MAIN BUTTON ----
*/

.btn-main {
    background: var(--main-pink);
    opacity: 1;
    margin: 3% 0;
    border: none;
    padding: 2% 4%;
    color: var(--sec-text);
    font-family: poppins-medium, sans-serif;
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}

.btn-main:hover {
    opacity: 0.7;
}

.btn-alt {
    background: var(--sec-purple);
}

.btn-main a {
    width: 100%;
    height: 100%;
}

.btn-like {
    cursor: pointer;
}


/*
---- BUTTON SIGN IN / OUT ----
*/

.btn-signin {
    background-color: var(--sec-purple);
}

.admin-nav button {
    margin-left: 4%;
    display: inline-block;
    font-family: poppins-regular, sans-serif;
}

.btn-signout {
    color: white;
}

.btn-signout:hover {
    opacity: 1 !important;
}


/*
---- BUTTON LINKS-RECHTS ----
*/

.btn-left,
.btn-right {
    width: 49%;
}

.btn-left {
    float: left;
    background: var(--sec-purple);
}

.btn-right {
    margin-left: 2%;
    background: var(--main-pink);
}


/*
---- BUTTON ADD SKILLS ----
*/

.btn-add-skill {
    height: auto;
    border: none;
    display: inline-block;
    background: none;
    margin: 0 2% 0 0;
    color: var(--main-pink);
    outline: none;
}

.btn-add-skill:hover {
    opacity: 0.7;
}


.btn-close {
    color: var(--sec-purple);
    background: none;
    font-size: 3em;
    padding: 0;
    margin: 0;
    border: none;
    float: right;
    height: auto;
}

/*
---- ICON BUTTONS ----
*/


/* -- SEARCH -- */

.search-icon {
    padding: 1%;
    font-size: 20px;
    color: var(--main-text);
    position: absolute;
    left: 6%;
}


/*
---- ADD TAG & ADD TILE BUTTON ----
*/

.btn-addTag,
.btn-addTile {
    width: 20%;
    float: right;
    margin: 0;
}


/* .addTag-field{
  display: none;
} */


/*

------ POPUP ------
bron: https://codepen.io/bastianalbers/pen/PWBYvz?editors=0010
*/

.clickable {
    cursor: pointer;
}

.popup {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-inner {
    z-index: 200;
    overflow-y: scroll;
    padding: 3%;
    width: 60%;
    position: fixed;
    left: 20%;
    right: 20%;
    top: 10%;
    height: auto;
    max-height: 80vh;
    margin: auto;
    background: white;
}

.popup-inner .btn-main {
    margin-bottom: 0;
}

/*

------ VIDEO CONTAINER ------ //File: AppIndex.jsx

*/

iframe {
    border-width: 0;
    width: 100%;
    height: 500px;
}


/*

------ FORM FOR STUDENT PERSONAL INFORMATION ------ //File: FormPersinfo.jsx

*/

fieldset {
    margin: 5%;
}

label {
    font-family: cooper-hewitt-book, sans-serif;
    color: var(--main-text);
}

input[type=text],
input[type=password],
input[type=email] {
    font-family: cooper-hewitt-book, sans-serif;
    color: var(--main-text);
    width: 100%;
    padding: 2% 4%;
    margin: 0.5% 0;
    display: inline-block;
    border: 1px solid var(--sec-purple);
    box-sizing: border-box;
    border-radius: 0;
}

input::placeholder {
    font-family: cooper-hewitt-italic, sans-serif;
}


/*
--- Form grid layout ---
*/

.form-column {
    margin: 5% 0;
    display: inline-block;
}

.form-column-1,
.form-column-2 {
    width: 49%;
}

.form-column-2 .custom-select {
    padding: 4% 8%;
    margin-top: 0.5%;
}

.form-column-2 {
    margin-left: 0;
    float: right;
}

.form-column input {
    padding: 4% 8%;
}


/*
---- FORM ERRORS ----
*/

.form-error {
    font-family: cooper-hewitt-medium, sans-serif;
    color: var(--main-pink);
}

button[disabled],
html input[disabled] {
    opacity: 0.5;
}

button[disabled]:hover,
html input[disabled]:hover {
    opacity: 0.5;
}

/*
---- UPLOAD IMAGE ----
*/

.personal-image {
    width: 100%;
}

.personal-image img {
    max-height: 30vh;
}

.tile-image {
    width: 100%;
}

.tile-image img {
    max-height: 30vh;
}

.tile-select-container {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
}

.tile-select-item {
    width: 18%;
    position: relative;
    margin: .3em;
    display: flex;
    align-items: center;
}

.tile-select-item > .img-container {
    padding-bottom: 4vh;
}

.tile-select-item > button {
    position: absolute;
    bottom: 0;
}

.tile-select-item img {
    max-width: 100%;
    max-height: 25vh;
}

input[type=file] {
    font-family: cooper-hewitt-book, sans-serif;
    color: var(--main-text);
    width: 90%;
    margin: 0.5% 0;
    display: inline-block;
    box-sizing: border-box;
}

.personal-image img:hover, .tile-image img:hover {
    opacity: 0.7;
}

.personal-image img, .tile-image img {
    cursor: pointer;
    display: block;
    margin: auto;
}

/*----- REVIEW PAGE -----*/

.review-page {
    width: 100%;
}

.review-page .general {
    display: flex;
    flex-flow: row;
}

.review-page .general > div.photo {
    flex: 0 0 25%;
}

.review-page .field-name::after {
    content: ':';
}

.personal {
    display: table;
    border-spacing: 1em .5em;
}

.personal .field-review {
    display: table-row;
}

.personal .field-name, .personal .field-value, .personal .field-error {
    display: table-cell;
}

input[type=text].field-error,
input[type=password].field-error,
input[type=email].field-error {
    color: var(--main-pink);
    border-color: var(--main-pink);
}

.field-error {
    color: var(--main-pink);
}

.review-page .general > div.photo img {
    max-width: 100%;
    max-height: 25vh;
}

.review-tile-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.review-tile-item {
    flex-basis: 25%;
    text-align: center;
}

.review-tile-item .img-container {
    width: 100%;
}

.review-tile-item .img-container img {
    max-width: 100%;
}

td.vcenter > * {
    display: inline-block;
    vertical-align: middle;
}

.tag-order-btn-container {
    width: unset;
    margin-left: .5em;
}

.tag-order-btn-container > button {
    display: block;
    font-size: 1.5em;
}

/*
---- SELECT ----
*/

.custom-select {
    font-size: inherit;
    background-color: var(--sec-text);
    font-family: cooper-hewitt-book, sans-serif;
    color: var(--main-text);
    padding: 2% 4%;
    margin: 0 0;
    border: 1px solid var(--sec-purple);
    box-sizing: border-box;
    text-align: left;
    border-radius: 0;
    height: auto;
    vertical-align: inherit;
    cursor: pointer;
    width: 100%;
}


/*
---- CHECKBOX ----
*/

.check-container div {
    display: inline-block;
    width: 23%;
    margin-right: 2%;
}

.check-container p {
    margin-bottom: 5px;
}

input[type=checkbox] + label {
    display: inline-block;
    margin-right: 5%;
}

input[type=checkbox] {
    display: none;
    font-family: cooper-hewitt-book, sans-serif;
}

input[type=checkbox] + label:before {
    content: "\2714";
    box-sizing: border-box;
    border: 0.1em solid var(--sec-purple);
    display: inline-block;
    width: 1.30em;
    height: 1.30em;
    padding-bottom: 1em;
    margin-right: 0.75em;
    color: transparent;
    text-align: center;
    vertical-align: bottom;
    transition: 0.2s;
}

input[type=checkbox]:checked + label:before {
    background-color: var(--sec-purple);
    color: white;
}


/*
---- SEARCH FIELD && FILTER FIELDS ----
*/

input.search-field,
select.filter-field {
    width: 19%;
    padding: 1.8%;
    padding-left: 3%;
    margin: 0.5% 0.3%;
}

input.search-field {
    padding-left: 4%;
}


/*

------ SESSIONS & TAGS OVERVIEW FOR ADMIN------ //File: TagOverview.jsx & TagOverview.jsx

*/

table,
.table {
    margin-top: 5%;
}

.img-tile {
    width: 10%;
    margin-right: 5%;
}

.table-icon {
    padding: 2%;
    margin: 2%;
    font-size: 25px;
}

table th,
table td {
    width: auto;
}

.table-img {
    width: 10%;
}

.table-overview tbody tr td {
    padding: 2% 0;
    vertical-align: unset;
}

table thead tr th {
    font-family: poppins-regular, sans-serif;
    padding-left: 0 !important;
}

.table-overview tbody tr td.td-action,
.td-action {
    text-align: center;
    padding-right: 0;
}

.addTileField,
.addTagField {
    width: 50%;
}

td.img-container {
    width: 15%;
}

.sessions-container {
    margin-top: 12%;
}


/*

------ TILES OVERVIEW FOR ADMIN------ //File: TileOverview.jsx

*/

.tile-overview {
    margin: 0;
    padding: 0;
    text-align: center;
    margin-top: 2%;
}

.tile-overview li {
    list-style: none;
    display: inline-block;
    width: 18%;
    margin: 0;
    margin-top: 2%;
}

.tile-overview .img-container {
    width: 100%;
}

.img-container div div {
    position: unset !important;
    text-align: center;
}

.tile-icon img {
    max-height: 25vh;
    max-width: 100%;
}

/*


------ ADMIN RESUME OVERVIEW ------


*/

.admin-resoverview {
    width: 100%;
}

.resume-container li {
    display: inline-block;
    width: 19%;
    margin: 0.3%;
    margin-bottom: 1%;
}

.res-persinfo-container {
    background-color: var(--sec-green);
    padding: 4%;
    text-align: center;
}

.res-persinfo-container img {
    width: 40%;
}

input.res-search {
    margin-left: 0.3%;
    width: 19%;
    margin-right: 0.3%;
    margin-bottom: 2%;
}

input.res-search:focus {
    margin-left: 0.3%;
    width: 19%;
}

.res-icon {
    padding: 1%;
    font-size: 20px;
    color: var(--main-text);
    position: absolute;
    right: 20%;
}


/* .persinfo-name */

.res-persinfo-container h3 {
    font-size: 20px;
    margin: 0;
    margin-top: 5%;
    font-family: cooper-hewitt-medium, sans-serif;
}


/*


------ ADMIN RESUME DETAIL ------


*/

.resume-detail-container {
    background-color: var(--sec-green);
    padding: 1%;
    display: inline-block;
}

.resume-detail-container h4 {
    font-size: 15px;
    margin-top: 1%;
}

.resume-detail-container .img-container {
    width: 50%;
    padding-top: 3%;
    float: left;
}

.resume-detail-container img {
    width: 50%;
}

.detail-info {
    /* float: right; */
    margin-left: 50%;
    width: 50%;
    padding-top: 8%;
}

.res-skill-container {
    width: 100%;
    margin-top: 1%;
    text-align: left;
}

.res-skill-container h4 {
    margin-top: 1%;
}

.res-img-container {
    width: 8%;
    display: inline-block;
    text-align: left;
    margin-right: 3%;
}

.res-img-container img {
    width: 100%;
}

.persinfo-session {
    font-family: cooper-hewitt-italic, sans-serif;
    text-align: right;
}

.check-detail {
    margin-top: 5%;
    display: block;
    width: 100%;
}

.res-img-despription {
    display: none;
    padding: 1%;
    text-align: center;
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 45%;
    right: 45%;
    top: 10px;

    color: white;
    background-color: var(--main-pink);
}

.res-img-container:hover .res-img-despription {
    display: block;
}

/*

------- Resume Add Skills ----- File: ResAddSkills.jsx e.a.

*/

h4.h-allrestags {
    margin-top: 3%;
    margin-bottom: 1.5%;
}

.tiles-selected-container {
    display: inline-flex;
    align-items: stretch;
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    width: 80%;
}

.tiles-selected-item {
    flex-shrink: 0;
    width: 14%;
    position: relative;
    margin: .3em;
    display: flex;
    align-items: center;
}

.tiles-selected-item > .img-container {
    max-width: 100%;
    padding: 2em 0;
}

.tiles-selected-item > .btn-close {
    font-size: 2em;
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    border: none;
}

.tiles-selected-item > h4 {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 1em;
    text-align: center;
}

.tiles-selected-item img {
    max-width: 100%;
    max-height: 12vh;
}

.tiles-selected-container::-webkit-scrollbar {
    /* display: none; */
    height: 6px;
}

.tiles-selected-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--main-bkg);
    border-radius: 10px;
}

/* Handle */
.tiles-selected-container::-webkit-scrollbar-thumb {
    background: var(--main-yellow);
    border-radius: 10px;
}


.error-block {
    margin: 2px 0;
    padding: 4px;
    background: lightcoral;
    border: solid 2px crimson;
    color: crimson;
}

.success-block {
    margin: 2px 0;
    padding: 4px;
    background: greenyellow;
    border: solid 2px green;
    color: green;
}

/*



--------- MEDIAQUERIES ---------



*/

@media screen and (min-width: 1280px) {
    .btn-add-skill {
        font-size: 8vw;
        text-align: left;
    }

    /* ---- POPUP ---- */
    .popup-inner {
        padding: 2%;
        width: 50%;
        left: 25%;
        right: 25%;
    }
}

@media screen and (max-width: 1280px) {
    .tiles-selected-item {
        width: 17%;
    }
}

@media screen and (max-width: 1000px) {
    /* ---- RESUME DETAIL ADMIN ---- */
    .resume-detail-container .img-container {
        width: 40%;
    }

    .resume-detail-container img {
        width: 70%;
    }

    .detail-info {
        width: 60%;
        margin-left: 40%;
    }

    .filter-form {
        font-size: 18px;
    }
}

@media screen and (min-width: 800px) and (max-width: 1350px) {
    /* ---- BUTTONS ---- */
    .btn-addTag,
    .btn-addTile {
        width: 50%;
    }

    /* ---- RESUME OVERVIEW ---- */
    .resume-container {
        font-size: 12px;
    }

    .resume-container h3 {
        font-size: 15px;
    }

    .btn-add-skill {
        font-size: 12vw;
    }
}


/* ----------- Tablets ----------- */
@media screen and (min-width: 550px) and (max-width: 800px) {
    header, h1 {
        font-size: 25px;
    }

    h4 {
        font-size: 15px;
    }

    /* ---- BUTTONS ---- */
    .btn-addTag,
    .btn-addTile {
        width: 100%;
        float: none;
    }

    .btn-back {
        font-size: 20px;
    }

    /* ---- POPUP ---- */
    .popup-inner {
        padding: 3%;
        width: 90%;
        left: 5%;
        right: 5%;
        top: 10%;
    }

    /* ---- SEARCH FIELDS ---- */
    input.search-field,
    select.filter-field {
        width: 49%;
        padding-left: 3%;
    }

    .search-icon {
        font-size: 15px;
    }

    /* ---- HOME SCREEN ---- */
    iframe {
        height: 360px;
    }

    /* ---- TILE OVERVIEW ---- */
    .tile-overview li {
        width: 30%;
    }

    /* ---- RESUME OVERVIEW ---- */
    .resume-container li {
        width: 32%;
    }

    .resume-container {
        font-size: 10px;
    }

    .resume-container h3 {
        font-size: 12px;
    }

    .res-icon {
        position: absolute;
        left: 6%;
        top: 35%;
        right: 90%;
    }

    input.search-field {
        padding-left: 6%;
    }

    /* ---- FORM STUDENT && RESUME ---- */
    .personal-image img {
        max-height: 25vh;
    }

    /* ---- FORM RESUME ADD SKILLS ---- */
    .tiles-selected-container h4 {
        font-size: 10px;
    }

    .tiles-selected-item {
        width: 18%;
    }

    .tiles-selected-item .img-container {
        padding: 1em 0;
    }

    .tile-select-item {
        width: 20%;
    }

    .btn-add-skill {
        font-size: 15vw;
    }

    .filter-form {
        font-size: 14px;
    }

    .filter-form > * {
        flex-basis: 23%;
    }
}


/* ----------- GSM's ----------- */

@media screen and (max-width: 550px) {
    header, h1 {
        font-size: 25px;
    }

    /* ---- BUTTONS ---- */
    .btn-addTag,
    .btn-addTile {
        width: 100%;
        float: none;
    }

    .btn-back {
        font-size: 20px;
    }

    /* ---- POPUP ---- */
    .popup-inner {
        padding: 3%;
        width: 85%;
        left: 2%;
        right: 2%;
        top: 5%;
    }

    /* ---- SEARCH FIELDS ---- */
    input.search-field,
    select.filter-field {
        width: 100%;
        padding-left: 1%;
        margin: 0.5% 0;
    }

    input.search-field {
        padding-left: 2%;
    }

    .res-icon,
    .search-icon {
        display: none;
    }

    /* ---- HOME SCREEN ---- */
    iframe {
        height: 200px;
    }

    /* ---- TILE OVERVIEW ---- */
    .tile-overview li {
        width: 50%;
    }

    /* ---- RESUME OVERVIEW ---- */
    .resume-container li {
        width: 49%;
        margin: 0.5%;
    }

    .resume-container {
        font-size: 12px;
    }

    .resume-container h3 {
        font-size: 15px;
    }

    /* ---- FORM STUDENT && RESUME ---- */
    .form-column {
        margin: 0;
    }

    .form-column input {
        padding: 2% 4%;
    }

    .form-column-1,
    .form-column-2 {
        width: 100%;
        margin: 5% 0;
    }

    .form-column-2 {
        float: none;
    }

    .form-column-2 .custom-select {
        padding: 2% 4%;
    }

    .personal-image img {
        max-height: 20vh;
    }

    .check-container div {
        width: 30%;
    }

    /* ---- FORM RESUMU ADD SKILLS ---- */
    .tiles-selected-container h4 {
        display: none;
    }

    .tiles-selected-item .img-container {
        padding: 1em 0 0;
    }

    .btn-add-skill {
        font-size: 18vw;
    }

    .tiles-selected-item {
        width: 20%;
    }

    .tiles-selected-item .img-container {
        padding: 1em 0 0;
    }

    /* ---- ADMIN RESUME DETAIL ---- */
    .resume-detail-container .img-container {
        width: 100%;
        float: none;
    }

    .resume-detail-container img {
        width: 30%;
    }

    .detail-info {
        margin-left: 0;
        width: 100%;
        padding-top: 2%;
    }

    .res-skill-container {
        width: 100%;
        margin-top: 1%;
        text-align: left;
    }

    .res-skill-container h4 {
        margin-top: 1%;
    }

    .res-img-container {
        width: 10%;
        margin: 0;
        padding: 0;
        display: inline-block;
        text-align: center;
        margin-right: 2%;
    }

    .res-img-container img {
        width: 100%;
    }

    .tile-select-item {
        width: 28%;
    }

    /*----- REVIEW PAGE -----*/
    .review-page .general {
        display: flex;
        flex-flow: column;
    }

    .review-page .general .photo {
        width: 100%;
        text-align: center;
    }

    .filter-form {
        flex-flow: wrap;
        font-size: 14px;
    }

    .filter-form > select {
        flex-basis: 100%;
    }

    .filter-form > * {
        flex-basis: 32%;
    }
}

@media screen and (max-width: 480px) {
    .tiles-selected-item {
        width: 30%;
    }

    .filter-form {
        flex-flow: column;
        text-align: left;
    }
}

@media screen and (max-width: 320px) {
    header, h1 {
        font-size: 20px;
    }

    /* ---- BUTTONS ---- */
    .btn-back {
        font-size: 15px;
    }

    /* ---- HOME SCREEN ---- */
    iframe {
        height: 100px;
    }

    /* ---- TILE OVERVIEW ---- */
    .tile-overview li {
        width: 100%;
    }

    /* ---- RESUME OVERVIEW ---- */
    .resume-container li {
        width: 100%;
        margin: 1% 0;
    }

    /* ---- FORM STUDENT && RESUME ---- */
    .personal-image img {
        max-height: 15vh;
    }

    .check-container div {
        width: 100%;
    }

    /* ---- FORM RESUMU ADD SKILLS ---- */
    .btn-add-skill {
        width: 100%;
        font-size: 3em;
    }

    .tiles-selected-container h4 {
        display: none;
    }

    .tiles-selected-item {
        width: 45%;
    }

    .tile-select-item {
        width: 46%;
    }
}

.export {
    padding: 0;
    margin: 0;
}

.export h1, .export h2, .export h3, .export h4, .export h5, .export h6, .export p, .export span, .export label {
    color: black;
}

.export > header {
    padding: .1em;
}

.export label {
    font-weight: normal;
}

.export > header > h1 {
    display: block;
    width: 100%;
    text-align: center;
}

.export .personal {
    display: flex;
    justify-content: center;
    align-items: center;
}

.export .personal > * {
    flex: 1 0 25%;
}

.export .personal-info {
    flex-grow: 2;
}

.export .personal .img-wrap {
    max-width: 30%;
    text-align: center;
}

.export .personal img {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    max-height: 25vh;
    max-width: 100%;
    image-orientation: from-image;
    border: solid 4px black;
}

.export .personal > div > div > span {
    padding-right: 1em;
}

.export .personal > div > div > span:first-child::after {
    content: ':';
}

.export div {
    width: unset;
}

.export .personal > div > div {
    padding: .5em;
}

.export > div {
    margin: 0 2em;
}

.export .tag-tiles {
    display: flex;
    flex-direction: column;
}

.export .tag {
    margin: .3em 0;
}

.export .tag h4 {
    width: 100%;
    margin: 0;
    text-align: center;
}

.export .tiles {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.export .tile {
    display: block;
    max-width: 14%;
    margin: 0 .2em;
    flex: 1 0 15%;
}

.export .tile span {
    display: block;
    width: 100%;
    text-align: center;
}

.export .tile img {
    max-width: 100%;
}

.export input[type=checkbox] + label {
    display: unset;
    margin: unset;
}

.export input[type=checkbox] {
    display: unset;
    font-family: cooper-hewitt-book, sans-serif;
}

.export input[type=checkbox] + label:before {
    content: unset;
    box-sizing: unset;
    border: unset;
    display: unset;
    width: unset;
    height: unset;
    padding-bottom: unset;
    margin-right: unset;
    color: unset;
    text-align: unset;
    vertical-align: unset;
    transition: unset;
}

.export input[type=checkbox]:checked + label:before {
    background-color: unset;
    color: unset;
}

.export input[type=checkbox] {
    margin-right: .3em;
}


main.monitor h1 {
    color: unset
}
